<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.measurements") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="drawer.create.status = true"
            >{{ $t("message.create") }}</el-button
          >
          <crm-column-settings
            :columns="columns"
            :modelName="'measurements'"
            @c-change="updateColumn"
          ></crm-column-settings>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.name"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.full_name"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.code"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.status"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.created_at"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.updated_at"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
          </tr>
          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                class="id_input"
                v-model="filterForm.id"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              ></el-input>
            </th>
            <th v-if="columns.full_name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.full_name"
                :placeholder="columns.full_name.title"
              ></el-input>
            </th>
            <th v-if="columns.code.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.code"
                :placeholder="columns.code.title"
              ></el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.updated_at"
                :placeholder="columns.updated_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="measurement in list"
            :key="measurement.id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ measurement.id }}</td>
            <td v-if="columns.name.show">{{ measurement.name }}</td>
            <td v-if="columns.full_name.show">{{ measurement.full_name }}</td>
            <td v-if="columns.code.show">{{ measurement.code }}</td>
            <td v-if="columns.status.show">{{ measurement.status }}</td>
            <td v-if="columns.created_at.show">{{ measurement.created_at }}</td>
            <td v-if="columns.updated_at.show">{{ measurement.updated_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="measurement"
                name="measurements"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="50%" :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
    >
      <CrmCreate :drawer-name="drawer.create.name" />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";

export default {
  name: "measurementsWarehousesList",
  mixins: [list, drawer],
  data() {
    return {
      loadingData: false,
      drawer: {
        create: {
          name: "create",
          status: false,
        },
      },
    };
  },
  components: {
    CrmCreate,
  },
  computed: {
    ...mapGetters({
      list: "measurements/list",
      columns: "measurements/columns",
      pagination: "measurements/pagination",
      filter: "measurements/filter",
      sort: "measurements/sort",
      socials: "socials/list",
      organizations: "organizations/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    this.controlExcelData();
    if (this.socials && this.socials.length === 0) await this.loadSocials();

    if (this.organizations && this.organizations.length === 0)
      await this.loadOrganizations();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "measurements/index",
      updateSort: "measurements/updateSort",
      updateFilter: "measurements/updateFilter",
      updateColumn: "measurements/updateColumn",
      updatePagination: "measurements/updatePagination",
      editModel: "measurements/show",
      empty: "measurements/empty",
      delete: "measurements/destroy",
      refreshData: "measurements/refreshData",
      loadSocials: "socials/index",
      loadOrganizations: "organizations/index",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            console.log(err);

            this.loadingData = false;
          });
      }
    },
    beforeClose(done) {
      this.emptyModel();
      this.refreshData();
      done();
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawer.create.status = true;
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
